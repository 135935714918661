import React, { useState, useEffect, useContext, useLayoutEffect } from "react"
import { Router, Location } from "@reach/router"
import { Link } from "gatsby"

import AuthContext from "../../context/AuthContext"

import Layout from '../../components/customlayout'


const AnyGuildPage = ({ location }) => {
    const [validGuild, setValidGuild] = useState(false);
    const [guild, setGuild] = useState({});
    const { isLogged, me, refreshMutual } = useContext(AuthContext);

    const [oncePageLoaded, setOPL] = useState(false);

    useEffect(() => {
        CheckGuildId(location, isLogged, me, setValidGuild, setGuild);
    }, [isLogged, me, me.mutualGuilds.length])

    useLayoutEffect(() => {
        if (!oncePageLoaded) {
            refreshMutual(() => {
                setOPL(true);
            });
        }

    }, [oncePageLoaded])
    let message = 'loading guild...';
    if (!validGuild) message = 'no guild found';
    if (!isLogged) message = 'login required';

    return (
        <Layout>
            <main className='container'>
                {validGuild ?
                    (<>
                        <h1 className="display-1 text-bold text-white">{guild.name}</h1>
                        <div className="container py-4">
                            <div className="p-5 mb-4 bg-success text-white rounded-3">
                                <div className="container-fluid py-5">
                                    <span className="badge badge-info bg-info">Beta</span>
                                    <h1 className="display-5 fw-bold">Manage your music!</h1>
                                    <p className="col-md-8 fs-4">Now you can change your music with your media keys! Just like the one in Spotify. Clicking your media keys on "Music Dashboard" now dispatches command on discord automaticly.</p>
                                    <Link to={`/dashboard/music/${guild.id}`} className="btn btn-dark btn-lg" type="button"> {'--->'} Music Dashboard</Link>
                                </div>
                            </div>

                            <div className="row align-items-md-stretch">
                                <div className="col-md-6">
                                    <div className="h-100 p-5 text-white bg-dark rounded-3">
                                        <span className="badge badge-warning bg-warning">Alpha</span>
                                        <h2>Moderate your guild</h2>
                                        <p>Be a server moderator. Good one.</p>
                                        <button className="btn btn-outline-light disabled" type="button">Maintenance</button>
                                    </div>
                                </div>
                                <div className="col-md-6 text-dark">
                                    <div className="h-100 p-5 bg-light border rounded-3">
                                        <span className="badge badge-warning bg-warning">Alpha</span>
                                        <h2>Settings</h2>
                                        <p>Change bot settings.</p>
                                        <button className="btn btn-outline-secondary disabled" type="button">Maintenance</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </>)
                    :
                    message}
            </main>
        </Layout>
    )
}

const CheckGuildId = (location, isLogged, me, setValid, setGuild) => {
    const route_id = location.pathname.split('/dashboard/')[1];
    if (route_id?.length !== 18) return setValid(false);
    if (!isLogged) return setValid(false);
    me.mutualGuilds.map(guild => {
        if (guild.id === route_id) {
            setValid(true);
            setGuild(guild);
            return
        }
    })

}

export default AnyGuildPage