import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { Link } from "gatsby"

import AuthContext from "../context/AuthContext"

const makeBtnColor = (color) => {
    switch (color) {
        case 'blue':
            return 'primary'
        case 'gray':
            return 'seconday'
        case 'green':
            return 'success'
        case 'cyan':
            return 'info'
        case 'yellow':
            return 'warning'
        case 'red':
            return 'danger'
        default:
            return color
    }
}

export default function DropdownClassic(props) {
    const { text, color, children } = props;
    const clr = makeBtnColor(color);

    const [btnState, setBtnState] = useState(false);
    const toggleNavDropdown = () => { setBtnState(!btnState) }

    return (
        <div className={`btn-group ${btnState ? 'show' : ''}`}>
            <button className={`btn btn-${clr} dropdown-toggle`} data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false" onClick={toggleNavDropdown}>
                {text}
            </button>
            <div className={`dropdown-menu ${btnState ? 'show' : ''}`}>
                {children}
                {/* <button className="dropdown-item">Action</button>
                <button className="dropdown-item">Another action</button>
                <button className="dropdown-item">Something else here</button>
                <div className="dropdown-divider"></div>
                <button className="dropdown-item">Separated link</button> */}
            </div>
        </div>
    )
}

DropdownClassic.propTypes = {
    text: PropTypes.string, //action
    color: PropTypes.string, // blue gray green cyan yellow red
    children: PropTypes.element, //buttons with dropdown-item clsname
    // clickhandler: PropTypes.func
}


export function DropdownMe(props) {
    const { isLogged, me, login, logout } = useContext(AuthContext);
    const { toggleDropdown, isOpen } = props;

    return (
        <>
            {isLogged ?
                <div className={`btn-group ${isOpen ? 'show' : ''}`}>
                    <Link to='/me' type="button" className="btn btn-success">Logged as {me.discordTag}</Link>
                    <button type="button" className="btn btn-warning dropdown-toggle dropdown-toggle-split"
                        data-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false" onClick={toggleDropdown}>
                        <span className="sr-only"></span>
                    </button>
                    <div className={`dropdown-menu dropdown-menu-end dropdown-menu-dark ${isOpen ? 'show' : ''}`}>
                        <Link to='/dashboard' className="dropdown-item">Dashboard</Link>
                        <Link to='/guilds' className="dropdown-item">My Servers
                            <span className="badge badge-pill bg-danger">{me.guilds?.length || 0}</span>
                        </Link>
                        <button className="dropdown-item disabled">More</button>
                        <div className="dropdown-divider"></div>
                        <button className="dropdown-item" onClick={logout} style={{ color: 'cadetblue' }}>Log out</button>
                    </div>
                </div>
                :
                <button type="button" className="btn btn-outline-light me-2" onClick={login}>Login</button>
            }
        </>
    )
}

DropdownMe.propTypes = {
    toggleDropdown: PropTypes.func,
    isOpen: PropTypes.bool,
    // setOpen: PropTypes.func
}
