import React from 'react'
import '../styles/error404.css'
import Header from './customheader'

export default function CustomLayout(props) {
    const { children } = props;
    return (
        <main className="layout d-flex flex-column">
            <Header />
            <div className="border-bottom bg-dark text-white" style={{flex: 1}}>
                {children}
            </div>
            <footer class="footer mt-auto py-3 bg-light">
                <div class="container">
                    <span class="text-muted">Eevnxxbot.</span>
                </div>
            </footer>
        </main>
    )
}
