import React, { useState, useContext, useEffect } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import AuthContext from "../context/AuthContext"

import { DropdownMe } from "../components/dropdowns"


const CustomHeader = (props) => {
  const {isLogged, me, login, logout} = useContext(AuthContext);

    const [btnState, setBtnState] = useState({nav: false});
    const toggleNavDropdown = () => setBtnState({nav: !btnState.nav})


    return (
        <header className="p-3 bg-dark text-white">
            <div className="container">
                <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
                    <a href="/" className="d-flex align-items-center mb-2 mb-lg-0 text-white text-decoration-none">
                        <h1>EevnxxBot</h1>
                        {/* <svg className="bi me-2" width="40" height="32"></svg> */}
                    </a>

                    <ul className="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0">
                        <li><Link to='/' className="nav-link px-2 text-secondary">Home</Link></li>
                        <li><Link to='/#features' className="nav-link px-2 text-white">Features</Link></li>
                        <li><Link to='/dashboard' className="nav-link px-2 text-white">Dashboard</Link></li>
                        <li><Link to='/faq' className="nav-link px-2 text-white">FAQs</Link></li>
                        <li><Link to='/about' className="nav-link px-2 text-white">About</Link></li>
                    </ul>

                    <div className="text-end">
                        <DropdownMe isOpen={btnState.nav} toggleDropdown={toggleNavDropdown} />
                    </div>
                </div>
            </div>
        </header>)
}

CustomHeader.propTypes = {
    siteTitle: PropTypes.string,
}

CustomHeader.defaultProps = {
    siteTitle: ``,
}

export default CustomHeader
